/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { stripslashes } from "../../Helpers/SettingHelper";
import noimage from "../../../common/images/home-page-noproduct.jpg";
import download from "../../../common/images/download-grey.png";
import orderonline from "../../../common/images/order-online-bag.png";
import { cateringId } from "../../Helpers/Config";
import cookie from "react-cookies";
var Parser = require("html-react-parser");

class Cateringproducts extends Component {
  constructor(props) {
    super(props);
    this.state = { categorylist: [], imagepath: [], displayCategory: "" };
  }
  componentDidMount() {
    if (this.props.categorylist.length > 0) {
      if (this.state.categorylist !== this.props.categorylist) {
        this.setState(
          {
            categorylist: this.props.categorylist[0].result_set,
            imagepath: this.props.categorylist[0].common,
          },
          function () {
            this.displayCategory();
          }
        );
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.categorylist.length > 0) {
      if (this.state.categorylist !== nextProps.categorylist) {
        this.setState(
          {
            categorylist: nextProps.categorylist[0].result_set,
            imagepath: nextProps.categorylist[0].common,
          },
          function () {
            this.displayCategory();
          }
        );
      }
    }
  }
  gotoCatering(link, event) {
    event.preventDefault();
    if (
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
    ) {
      if (cookie.load("defaultAvilablityId") !== cateringId) {
        cookie.save("defaultAvilablityId", cateringId, { path: "/" });
        this.props.PropsData.history.push(link);
      } else {
        this.props.PropsData.history.push(link);
      }
    } else {
    }
  }
  displayCategory() {
    let cat,
      firstcategory = "";

    if (this.state.categorylist !== "") {
      cat = this.state.categorylist.map((item, index) => {
        if (index === 0) {
          firstcategory += item.pro_cate_slug + "/";
          if (item.subcategory.length > 0) {
            firstcategory += item.subcategory[0].pro_subcate_slug;
          }
        }
        return (
          <li key={index}>
            {index === 0 && (
              <input type="hidden" id="firstcategory" value={firstcategory} />
            )}
            <div className="home-option-parent">
              <div className="home-option-img">
                {item.pro_cate_image !== "" && item.pro_cate_image !== null ? (
                  <img
                    src={
                      this.state.imagepath.cat_image_source +
                      "/" +
                      item.pro_cate_image
                    }
                  />
                ) : (
                  <img src={noimage} />
                )}
              </div>
              <div className="home-option-desc">
                <h5>{stripslashes(item.pro_cate_name)}</h5>
                <p className="home_desc">
                  {item.pro_cate_short_description !== "" &&
                  item.pro_cate_short_description !== null
                    ? Parser(stripslashes(item.pro_cate_short_description))
                    : ""}
                </p>
              </div>
              <div className="home-order-btn">

                {item.pro_cate_name === "Foreign Workers Meal" || item.pro_cate_name === "Shell Out & Bucket Briyani"   ?  <a
                  href={void 0}
                  className="home-btn button"
                  title="Call Us"
                >
                 {item.pro_cate_name === "Shell Out & Bucket Briyani" ?  "Temporarily Unavailable" : "Call Us"}
                </a> : <a
                  href={void 0}
                  className="home-btn button"
                  onClick={this.gotoCatering.bind(
                    this,
                    "/catering/" +
                      item.pro_cate_slug +
                      "/" +
                      item.subcategory[0].pro_subcate_slug
                  )}
                >
                 Order Now
                </a> }
                
              </div>
            </div>
            {/* <div className="mobile-choose-option-hover">
              <ul className="imp-button-mob">
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <img src={orderonline} alt="" /> Order Online
                  </a>
                  <ul>
                    {item.subcategory !== "" &&
                      item.subcategory.map((subitem, index1) => {
                        return (
                          <li key={index1}>
                            <Link
                              to="/"
                              onClick={this.gotoCatering.bind(
                                this,
                                "/catering/" +
                                  item.pro_cate_slug +
                                  "/" +
                                  subitem.pro_subcate_slug
                              )}
                            >
                              {subitem.pro_subcate_name}
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>
                <li>
                  <a href="#">
                    <img src={download} alt="" />
                    Download PDF
                  </a>
                </li>
              </ul>
            </div> */}
          </li>
        );
      });
    }
    if (firstcategory !== "") {
    }
    this.setState({ displayCategory: cat });
  }
  render() {
    return this.state.displayCategory;
  }
}

export default Cateringproducts;
