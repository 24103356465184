import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";

import Home from "./components/Home/Home";
import Products from "./components/Products/Products";
import Checkout from "./components/Checkout/Checkout";
import Thankyou from "./components/Checkout/Thankyou";
import Catering from "./components/Catering/index";
import Editcart from "./components/Catering/Editcart";
import Cateringsuccess from "./components/Catering/Refpage";
import Cateringcheckout from "./components/Cateringcheckout/index";
import CateringThankyou from "./components/CateringThankyou/index";
import Payment from "./components/Payment/Payment";
import PyamentThankyou from "./components/Payment/Thankyou";
import Pages from "./components/Pages/Pages";
import ContactUs from "./components/Pages/ContactUs";
import Outlets from "./components/Pages/Outlets";
import Myaccount from "./components/Myaccount/Myaccount";
import Orders from "./components/Myaccount/Orders";
import Mypromotions from "./components/Myaccount/Mypromotions";
import Rewards from "./components/Myaccount/Rewards";
import Account from "./components/Account/Account";
import Resetpassword from "./components/Account/Resetpassword";
import Logout from "./components/Myaccount/Logout";
import Refpage from "./components/Layout/Refpage";
import Page404 from "./Page404";

const store = getStore();

render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          path={"/products/:slugType/:slugValue/:proValue"}
          component={Products}
        />
        <Route path={"/products/:slugType/:slugValue"} component={Products} />
        <Route path="/products" component={Products} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/thankyou/:orderId" component={Thankyou} />
        <Route path="/catering-checkout" component={Cateringcheckout} />
        <Route path="/catering/payment/thankyou" component={PyamentThankyou} />
        <Route
          path="/catering/thankyou/:orderId"
          component={CateringThankyou}
        />
        <Route path="/catering/edit/:productId/:cartID" component={Catering} />
        <Route
          path="/catering-success/:catSlug/:subCatSlug"
          component={Cateringsuccess}
        />
        <Route path="/catering/:catSlug/:subCatSlug" component={Catering} />
        <Route path="/catering" component={Catering} />

        <Route path="/payment/:orderId/:paymentId" component={Payment} />
        <Route path="/locations" component={Pages} />
        <Route path="/news-updates" component={Pages} />
        <Route path="/page/:page_slug" component={Pages} />
        <Route path="/about-us" component={Pages} />
        <Route path="/privacy-policy" component={Pages} />
        <Route path="/terms-conditions" component={Pages} />
        <Route path="/faqs" component={Pages} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/location" component={Outlets} />
        <Route path="/rewards" component={Rewards} />
        <Route path="/mypromotions" component={Mypromotions} />
        <Route path="/myorders" component={Orders} />
        <Route path="/myaccount" component={Myaccount} />
        <Route path="/account/activation/:activationKey" component={Account} />
        <Route
          path="/account/resetpassword/:resetKey"
          component={Resetpassword}
        />
        <Route path="/logout" component={Logout} />
        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
