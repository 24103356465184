/* eslint-disable */
import React from "react";
import cookie from "react-cookies";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import swal from "sweetalert";
import ReactTooltip from "react-tooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import delete_dark from "../../common/images/delete-dark.png";
import delete_white from "../../common/images/delete-white.png";
import warningone from "../../common/images/warning-block.svg";
import cart_blue from "../../common/images/cart-blue.png";
import noimage from "../../common/images/no-img-product.png";
import gridimg from "../../common/images/grid.png";
import filterImg from "../../common/images/filter.svg";
import searchimg from "../../common/images/search.svg";
import closebl from "../../common/images/close-block.png";
import crossImg from "../../common/images/cross1.png";
import {
  appId,
  apiUrlCtrng,
  apiUrl,
  apiUrlV2,
  deliveryId,
  cateringId,
  CountryTxt,
  baseUrl,
} from "../Helpers/Config";
import moment from "moment";
import {
  GET_CATEGORY_LIST,
  GET_CATERING_PRODCUTLIST,
  GET_STATIC_BLOCK,
  UPDATE_CART_DETAIL,
  GET_CARTTIMEDATA,
  GET_CARTDATEDATA,
  GET_AVAILABLE_DATE,
  GET_CARTLIST,
  GET_DELETE_CATERING_CART,
  GET_UPLOAD_IMAGE,
  GET_DELETE_IMAGE,
  GET_CATERINGCART_DETAIL
} from "../../actions";
import Category from "./Category";
import Product from "./Product";

import {
  stripslashes,
  getReferenceID,
  showPriceValue,
  getGstValue,
  getSurchargesplitup,
  showPriceValueNew,
  validateIntval,
  showLoader,
  hideLoader,
} from "../Helpers/SettingHelper";
var Parser = require("html-react-parser");
var qs = require("qs");
const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3500,
};
const settings1 = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  dots: true,
  arrows: false,
};
class Index extends React.Component {
  constructor(props) {
    super(props);
    var postal_code =
      cookie.load("catering_postal_code") !== "" &&
      typeof cookie.load("catering_postal_code") !== undefined &&
      typeof cookie.load("catering_postal_code") !== "undefined"
        ? cookie.load("catering_postal_code")
        : "";
    var address_line1 = cookie.load("catering_address_line")
      ? cookie.load("catering_address_line")
      : "";
    var unit_number1 = cookie.load("catering_floor_no")
      ? cookie.load("catering_floor_no")
      : "";
    var unit_number2 = cookie.load("catering_unit_no")
      ? cookie.load("catering_unit_no")
      : "";
    var zone_id = cookie.load("catering_zone_id")
      ? cookie.load("catering_zone_id")
      : "";
    var outlet_id = cookie.load("outlet_id") ? cookie.load("outlet_id") : "";
    this.state = {
      cartcount: 0,
      catSlug: this.props.match.params.catSlug,
      subCatSlug: this.props.match.params.subCatSlug,
      selproductId: this.props.match.params.productId,
      cartID: this.props.match.params.cartID,
      defaultAvilablityId: cookie.load("defaultAvilablityId"),
      postal_code: postal_code,
      catering_address_line: address_line1,
      unit_number1: unit_number1,
      unit_number2: unit_number2,
      zone_id: zone_id,
      outlet_id: outlet_id,
      product_loading: true,
      productData: [],
      addBreakTime: 0,
      currentSlot: 0,
      orderDate:
        cookie.load("order_date") !== "" &&
        typeof cookie.load("order_date") !== undefined &&
        typeof cookie.load("order_date") !== "undefined"
          ? cookie.load("order_date")
          : "",
      dayList: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
      postalcode_error: "",
      orderdate_error: "",
      startslot_error: "",
      endslot_error: "",
      cartDetailsMain: "",
      cartItems: [],
      cartcount: 0,
      cartDisplay: "",
      staticblockData: [],
      delivery_blocks: "",
      zoneDetails: [],
      surcharge: 0,
      surchargeLog: "",
      settings: [],
      start_time: "",
      end_time: "",
      cart_items: [],
      disableSlot: [],
      cart_zone_delivery_charge: 0,
      start_validate: false,
      end_validate: false,
      cartAvailbility: "",
    };
  }

  componentDidMount() {
    this.props.getCategoryList();
    this.props.getStaticBlock();
    this.getCommonSetting();
    let parameter = "";
    if (this.props.match.path === "/catering/edit/:productId/:cartID") {
      parameter =
        "&product_id=" + this.state.selproductId + "&product_is_combo=Yes";
    } else {
      parameter =
        "&subcategory_slug=" + this.state.subCatSlug + "&product_is_combo=Yes";
    }
    this.props.getCateringProductList(parameter);
    if (
      typeof cookie.load("outlet_id") !== "undefined" ||
      cookie.load("outlet_id") !== ""
    ) {
      this.props.getCartdatedata({
        dateFlagkey: "START",
        outletId: cookie.load("outlet_id"),
      });
    }
    showLoader("product-lhs-inner", "class");
  
   $(".order_date").focus(function(e) {
      var postal_code = $("#postal_code").val();
      if (postal_code === "") {
        swal("Error!", "Please enter valid Postal code!", "error");
      }
    });

  }
  componentWillMount() {
    
  }
  componentWillReceiveProps(nextProps) {
    if (this.state.subCatSlug !== nextProps.match.params.subCatSlug) {
      this.setState(
        {
          subCatSlug: nextProps.match.params.subCatSlug,
          product_loading: true,
        },
        function () {
          let parameter =
            "&subcategory_slug=" +
            nextProps.match.params.subCatSlug +
            "&product_is_combo=Yes";
          this.props.getCateringProductList(parameter);
        }
      );
    }
    if (this.state.productData !== nextProps.productData) {
      if (nextProps.productData.length > 0) {
        this.setState({
          productData: nextProps.productData,
          product_loading: false,
        });
      }
    }
    /*Get date list*/
    if (
      this.props.cartdate !== nextProps.cartdate &&
      typeof nextProps.cartdate !== "undefined"
    ) {
      if (nextProps.cartdate[0].status === "success") {
        let dateArrList = [];
        let commonArrLt = [];
        let timeslotArrLt = [];
        let interval_time = 15;
        let mindate = new Date();
        let maxdate = "";

        dateArrList = nextProps.cartdate[0].result_set;
        commonArrLt = nextProps.cartdate[0].common_data;
        timeslotArrLt = nextProps.cartdate[0].timeslot_data;
        interval_time = nextProps.cartdate[0].interval_time;

        let data_max = Object.keys(dateArrList).length;
        let ind = parseInt(data_max) - parseInt(1);
        maxdate = new Date(nextProps.cartdate[0].result_set[ind].fulldate);
        this.setState({ available_datelist: dateArrList });
        this.setState({ common_datalist: commonArrLt });
        this.setState({ time_slotlist: timeslotArrLt });
        this.setState({ interval_time_sat: interval_time });
        var catergDate = [];
        var disable_dates = [];
        if (dateArrList.length > 0) {
          dateArrList.map((date, index) => {
            if (date.isclose === "No") {
              catergDate.push(new Date(date.fulldate));
              return false;
            } else {
              disable_dates.push(new Date(date.fulldate));
              return false;
            }
          });
        }
        mindate = new Date(catergDate[0]);
        var errorHtml = "";
        if (Object.keys(dateArrList).length > 0) {
          this.setState({ date_errorHtml: "" });
        } else {
          this.setState({ date_errorHtml: errorHtml });
        }
        this.setState(
          {
            catTimeLoading: false,
            catering_date: catergDate,
            disable_dates: disable_dates,
            mindate: mindate,
            maxdate: maxdate,
          },
          function () {
            this.loadCartDet();
          }
        );
      }
    }
        /* Static Block */
    if (this.state.staticblockData !== nextProps.staticblockData) {
      if (
        nextProps.staticblockData !== "" &&
        typeof nextProps.staticblockData !== undefined &&
        typeof nextProps.staticblockData !== "undefined"
      ) {
        this.setState({ staticblockData: nextProps.staticblockData });
        if (nextProps.staticblockData.length > 0) {
          if (nextProps.staticblockData[0].status !== "error") {
            nextProps.staticblockData[0].result_set.map((data) => {
              if (data.staticblocks_slug === "delivery-charge-calculation") {
                var delivery_block = data.staticblocks_description;
                this.setState({ delivery_blocks: delivery_block });
              }
            });
          }
        }
      }
    }
    /* Delete Cart Item */
    if (
      nextProps.deleteDetail &&
      this.props.deleteDetail !== nextProps.deleteDetail
    ) {
      if (nextProps.deleteDetail.length > 0) {
        hideLoader("catering_cart_list", "class");
        if (nextProps.deleteDetail[0].status === "ok") {
          swal("Success!", nextProps.deleteDetail[0].message, "success");
          this.loadCartDet();
          this.props.getCateringCartDetail();
        } else {
          swal("Error!", nextProps.deleteDetail[0].message, "error");
        }
      }
    }
  }


   showSuccess() {
    if (this.state.start_validate && this.state.end_validate) {
      var startTime = this.state.start_time;
      var endTime = this.state.end_time;
      if (
        startTime !== "" &&
        endTime !== "" &&
        startTime !== undefined &&
        endTime !== undefined
      ) {
        if (startTime >= endTime) {
          swal("Error!", "Invalid start & end time!", "error");
          this.setState({ start_validate: false, end_validate: false });
          $('.start_time').prop('selectedIndex',0);
          $('.end_time').prop('selectedIndex',0);
        } else {
          this.setState({ start_time: startTime, end_time: endTime });
        }
      }
    }
   }

 /* chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    let defaultAvilablityId = this.state.defaultAvilablityId;
    if (defaultAvilablityId !== availability) {
      window.$.magnificPopup.open({
        items: {
          src: "#warning-popup",
        },
        type: "inline",
      });
    }
  } */
  closeCartlist() {
    $("body").removeClass("cart-items-open");
    $(".hcart_dropdown").removeClass("open").removeClass("active");
  }
  getCommonSetting(pstCode) {
    var pstCode = pstCode === "" ? this.state.postal_code : "";
    var postCdVal = pstCode !== "" ? "&postal_code=" + pstCode : "";
    axios
      .get(apiUrl + "settings/get_common_settings?app_id=" + appId + postCdVal)
      .then((res) => {
        if (res.data.status === "ok") {
          if (typeof res.data.result_set !== "undefined") {
            this.setState({ settings: res.data.result_set });
          }
        }
      });
  }


 changePostalCode(event) {
    var postalCode = event.target.value;
    var postCdLth = postalCode !== "" ? postalCode.length : 0;
    var outletIdVl = cookie.load("cateringOutletId");
    cookie.remove("catering_postal_code");
    cookie.remove("catering_address_line");
    if (postalCode !== "" && parseInt(postCdLth) >= 6 && outletIdVl !== "") {
      var urlShringTxt =
        apiUrlV2 +
        "outlets/findOutletZone?app_id=" +
        appId +
        "&availability_id=" +
        cateringId +
        "&postal_code=" +
        postalCode +
        "&postalcode_basedoutlet=yes";

      axios.get(urlShringTxt).then(res => {
        var custAddress = "";
        if (res.data.status === "ok") {
          var outltResulSet = res.data.result_set;
          if (Object.keys(outltResulSet.postal_code_information).length > 0) {
            var outletAddress =
              outltResulSet.postal_code_information.zip_buno !== ""
                ? outltResulSet.postal_code_information.zip_buno +
                  ", " +
                  outltResulSet.postal_code_information.zip_sname
                : outltResulSet.postal_code_information.zip_sname;

            custAddress = outletAddress + " " + CountryTxt;
          }
          cookie.save("orderOutletId", outltResulSet.outlet_id);
          $(".postal_code_error").hide();
          this.getCommonSetting(postalCode);
          cookie.save("catering_postal_code", postalCode);
          cookie.save("catering_address_line", custAddress);
          this.setState({ catering_postal_code: postalCode });
          this.setState({ postalcode_error: "" });
          this.setState({ catering_address_line : custAddress });
          this.setState({ postal_code : postalCode });

        } else {
          swal("Error!", "Pleae enter valid Postal code!", "error");
          cookie.save("order_date", "");
          this.setState({ postal_code: ""});
          this.setState({ orderDate: "" });
          cookie.save("catering_postal_code", "");
          this.setState({ catering_postal_code: "" });
          cookie.save("catering_address_line", "");
          this.setState({ catering_address_line: "" });
        }
      });
    } else {
      cookie.save("catering_postal_code", postalCode);
      this.setState({
        catering_postal_code: postalCode,
        catering_address_line: ""
      });
    }
  }

  /*changePostalCode(event) {
    var postalCode = event.target.value;
    var postCdLth = postalCode !== "" ? postalCode.length : 0;
    if (postalCode !== "" && parseInt(postCdLth) >= 6) {
        var urlShringTxt =
          apiUrlV2 +
          "outlets/findOutletZone?app_id=" +
          appId +
          "&availability_id=" +
          cateringId +
          "&postal_code=" +
          postalCode +
          "&postalcode_basedoutlet=yes";
        axios.get(urlShringTxt).then((res) => {
           var custAddress = "";
          if (res.data.status === "ok") {
            var outltResulSet = res.data.result_set;

           if (Object.keys(outltResulSet.postal_code_information).length > 0) {
            var outletAddress =
              outltResulSet.postal_code_information.zip_buno !== ""
                ? outltResulSet.postal_code_information.zip_buno +
                  ", " +
                  outltResulSet.postal_code_information.zip_sname
                : outltResulSet.postal_code_information.zip_sname;

            custAddress = outletAddress + " " + CountryTxt;
           }

            cookie.save(
              "catering_address_line",
              custAddress,
              { path: "/" }
            );
            cookie.save(
              "catering_floor_no",
              outltResulSet.outlet_unit_number1,
              { path: "/" }
            );
            cookie.save("catering_unit_no", outltResulSet.outlet_unit_number2, {
              path: "/",
            });
            cookie.save("catering_zone_id", outltResulSet.zone_id, {
              path: "/",
            });
            cookie.save("outlet_id", outltResulSet.outlet_id, { path: "/" });
            cookie.save("catering_postal_code", postalCode, { path: "/" });

            this.setState({
              address_line1: custAddress,
              unit_number1: outltResulSet.outlet_unit_number1,
              unit_number2: outltResulSet.outlet_unit_number2,
              zone_id: outltResulSet.zone_id,
              outlet_id: outltResulSet.outlet_id,
              catering_postal_code : postalCode,
              postalcode_error: "",
            });
          } else {
            swal("Error!", "Pleae enter valid Postal code!", "error");
            this.setState({ postal_code: ""});
          }
        });
      }else{
         cookie.save("catering_postal_code", postalCode);
         this.setState({
         catering_postal_code: postalCode,
         catering_address_line: ""
         });
      }
  }*/

  getZoneDetails() {
    if (this.state.zone_id !== "") {
      var urlShringTxt =
        apiUrlV2 +
        "outlets/getZoneDetails?app_id=" +
        appId +
        "&availability=" +
        cateringId +
        "&outletId=" +
        this.state.outlet_id +
        "&ZoneId=" +
        this.state.zone_id;
      axios.get(urlShringTxt).then((res) => {
        if (res.data.status === "ok") {
          var zoneResulSet = res.data.result_set;
          this.setState({
            zoneDetails: zoneResulSet[0],
          });
        }
      });
    }
  }

  handleChange = (date) => {
    if (date === null) {
      date = "";
      cookie.save("order_date", "", { path: "/" });
      this.setState({ orderDate: date, orderdate_error: 1 });
    } else {
      cookie.save("order_date", moment(date).format("MM/DD/YYYY"), {
        path: "/",
      });
      this.setState({ orderDate: date, orderdate_error: "" }, function () {
        this.loadCartDet();
      });
    }
  };

  handleUpdateSlotTime(e) {
    if (e.target.name === "start_time") {
      if (e.target.value !== "") {
        this.setState({ startslot_error: "",  start_validate: true, start_time:e.target.value},function(){
          this.showSuccess();
        }.bind(this));
      } else {
        this.setState({ startslot_error: 1,  start_validate: false}, function(){
          this.showSuccess();
        }.bind(this));
      }
    } else if (e.target.name === "end_time") {
      if (e.target.value !== "") {
        this.setState({ endslot_error: "", end_validate: true, end_time: e.target.value}, function(){
          this.showSuccess();
        }.bind(this));
      } else {
        this.setState({ endslot_error: 1, end_validate: false}, function(){
          this.showSuccess();

        }.bind(this));
      }
    }
  }

  handleAddrChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    if (event.target.name === "unit_number1") {
      cookie.save("catering_floor_no", event.target.value, { path: "/" });
    }
    if (event.target.name === "unit_number2") {
      cookie.save("catering_unit_no", event.target.value, { path: "/" });
    }
    if (event.target.name === "address_line2") {
      this.setState({ catering_address_line2: event.target.value });
    }
  }
  addBreakTime() {
    var totalSlot = $(".product-rhs .order_card").length;
    this.setState({ addBreakTime: 1, currentSlot: totalSlot }, function () {
      $(".product-rhs .addition-breaktime").attr("id", "slot_" + totalSlot);
      this.getCartDetList();
      ReactTooltip.rebuild();
    });
  }
  getbreaktimelist(selectedSlot, currentSlot, disable_Slot) {
    var exceptFromSlot = [];
    var exceptToSlot = [];
    if (
      selectedSlot === "" ||
      typeof selectedSlot === undefined ||
      typeof selectedSlot === "undefined"
    ) {
      selectedSlot = "";
    }
    if (
      currentSlot === "" ||
      typeof currentSlot === undefined ||
      typeof currentSlot === "undefined"
    ) {
      currentSlot = "";
    }
    if (
      disable_Slot === "" ||
      typeof disable_Slot === undefined ||
      typeof disable_Slot === "undefined"
    ) {
      disable_Slot = "";
    } else {
      disable_Slot.forEach((value) => {
        if (currentSlot !== value) {
          var timeList = value.split("-");
          exceptFromSlot.push(timeList[0]);
          exceptToSlot.push(timeList[1]);
        }
      });
    }
    var time_slotlist = this.state.time_slotlist;
    var orderDate = this.state.orderDate;
    if (orderDate !== "" && time_slotlist !== "") {
      orderDate = new Date(orderDate);
      var orderDay = orderDate.getDay();
      orderDay = this.state.dayList[orderDay];
      if (
        time_slotlist[orderDay] !== "" &&
        typeof time_slotlist[orderDay] !== undefined &&
        typeof time_slotlist[orderDay] !== "undefined"
      ) {
        if (time_slotlist[orderDay].length > 0) {
          var start = 0;
          var end = 0;
          var hideStart = 0;
          return time_slotlist[orderDay].map((time, index) => {
            if (exceptFromSlot.length > 0) {
              if (exceptFromSlot.indexOf(time) >= 0 && start === 0) {
                start = 1;
                hideStart = 1;
              }
              if (exceptToSlot.indexOf(time) >= 0 && start === 1) {
                start = 0;
                hideStart = 0;
              }
            }
            return (
              <option
                key={index}
                value={time}
                selected={time == selectedSlot ? true : false}
                disabled={hideStart === 1 ? true : false}
              >
                {time}
              </option>
            );
          });
        }
      }
    }
  }

  sateValChange = (field, value) => {
    if (field === "postalcode_error") {
      this.setState({ postalcode_error: value });
    if ($("body").width() <= 980) {
        $("body").addClass("cart-items-open");
        $(this).toggleClass("active");
        $(".hcart_dropdown").toggleClass("open");
      }
    } else if (field === "orderdate_error") {
      this.setState({ orderdate_error: value });
        if ($("body").width() <= 980) {
          $("body").addClass("cart-items-open");
          $(this).toggleClass("active");
          $(".hcart_dropdown").toggleClass("open");
        }
    } else if (field === "orderslot_error") {
      if (value !== "") {
        this.setState(
          { addBreakTime: 1, startslot_error: 1, endslot_error: 1 },
          function () {
            console.log(value, "value");
            $(".product-rhs .order_card").attr("id", "slot_0");
 
              $("html, body").animate(
              {
                scrollTop: $(".order_card").offset().top - 150,
              },
              500
            );

            if ($("body").width() <= 980) {
              $("body").addClass("cart-items-open");
              $(this).toggleClass("active");
              $(".hcart_dropdown").toggleClass("open");
            }

          }
        );
      }
    } else if (field === "startslot_error") {
      this.setState({ startslot_error: value });
    } else if (field === "endslot_error") {
      this.setState({ endslot_error: value });
    }
  };

  loadCartDet() {
    var customerParam = "";
    if (typeof cookie.load("UserId") === "undefined") {
      customerParam = "&reference_id=" + getReferenceID();
    } else {
      customerParam = "&customer_id=" + cookie.load("UserId");
    }
    if (this.state.zone_id !== "") {
      customerParam += "&zone_id=" + this.state.zone_id;
    }
    axios
      .get(apiUrlCtrng + "cart/contents?app_id=" + appId + customerParam)
      .then((res) => {
        let cartDetailsMain = "";
        let cartItems = [];
        let cartcount = 0;
        let cart_zone_delivery_charge = 0;
        if (res.data.status === "ok") {
          cartDetailsMain = res.data.result_set.cart_details;
          cartItems = res.data.result_set.cart_items;
          cartcount = res.data.result_set.cart_details.cart_total_items;
          if (
            cartDetailsMain.cart_zone_delivery_charge !== "" &&
            typeof cartDetailsMain.cart_zone_delivery_charge !== "undefined" &&
            typeof cartDetailsMain.cart_zone_delivery_charge !== undefined
          ) {
            cart_zone_delivery_charge =
              cartDetailsMain.cart_zone_delivery_charge;
          }
        }
        this.setState(
          {
            cartDetailsMain: cartDetailsMain,
            cartItems: cartItems,
            cartcount: cartcount,
            cart_zone_delivery_charge: cart_zone_delivery_charge,
          },
          function () {
            this.getZoneDetails();
            this.getSurCharge();
            this.getCartDetList();
            if (this.state.selproductId !== "") {
              this.editProductDetails();
            }
          }
        );
      });
  }
  editProductDetails() {
    var cartID = this.state.cartID;
    var EcartItems = this.state.cartItems[cartID];
    var proDetails = this.state.productData;
  }

  getSurCharge() {
    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + getReferenceID();
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId");
    }
    axios
      .get(apiUrlCtrng + "orders/getSurCharge?app_id=" + appId + customerParam)
      .then((res) => {
        if (res.data.status === "ok") {
          var surcharge = 0;
          if (
            typeof res.data.total_charge !== "undefined" &&
            res.data.total_charge > 0
          ) {
            surcharge = res.data.total_charge;
          }
          this.setState({ surcharge: surcharge, surchargeLog: res.data.logs });
        }
      });
  }

  getCartDetList() {
    var cart_items = [];
    var cartDisplay = "";
    var disableSlot = [];
    if (this.state.cartItems.length > 0) {
      this.state.cartItems.map((item) => {
        var slotList =
          item.cart_item_breaktime_started +
          "-" +
          item.cart_item_breaktime_ended;
        disableSlot.push(slotList);
        if (
          cart_items[slotList] === "" ||
          typeof cart_items[slotList] === undefined ||
          typeof cart_items[slotList] === "undefined"
        ) {
          cart_items[slotList] = [];
        }
        cart_items[slotList].push(item);
      });

      cartDisplay = (
        <div>
          {this.cartBrkTimeList(cart_items, disableSlot)}
          <ReactTooltip />
        </div>
      );
    }

    this.setState({
      cart_items: cart_items,
      cartDisplay: cartDisplay,
      disableSlot: disableSlot,
    });
  }
  cartBrkTimeList(cart_items, disableSlot) {
    ReactTooltip.rebuild();
    var currentSlot = this.state.currentSlot;
    return Object.keys(cart_items).map((item, index) => {
      var items = cart_items[item];
      var slotListKey = item.split("-");
      return (
        <div
          className={
            currentSlot === index ? "order_card dark" : "order_card light"
          }
          key={index}
          id={"slot_" + index}
        >
          <div
            className="order_card_head"
            onClick={this.selectCurrentSlot.bind(this, index)}
          >
            <h5>
              Order Details {"-"} {parseInt(index) + 1}
            </h5>
            <Link
              onClick={this.deleteCartItm.bind(
                this,
                items[0]["cart_item_id"],
                "alert"
              )}
              title = "Delete"
              to="#"
            >
              <img
                src={currentSlot === index ? delete_white : delete_dark}
                alt="delete"
              />
            </Link>
          </div>
          <div className="order_card_body">
            <div className="event_timing">
              <div className="form-group">
                <div className="input_field">
                  <label>Event Start Time</label>
                  <div className="custom_select">
                    <select
                      className="form-control start_time"
                      name="start_time"
                      defaultValue={slotListKey[0]}
                      onChange={this.handleUpdateTime.bind(this, item)}
                    >
                      <option value="">Select</option>
                      {this.getbreaktimelist(slotListKey[0], item, disableSlot)}
                    </select>
                  </div>
                </div>
                <span
                  data-tip={this.state.delivery_blocks}
                  data-html={true}
                  data-class="default_tooltip order_tooltip"
                  className="tooltip_link"
                >
                  <img src={warningone} alt="warning" />
                </span>
              </div>
              <div className="form-group">
                <div className="input_field">
                  <label>Event End Time</label>
                  <div className="custom_select">
                    <select
                      className="form-control end_time"
                      name="end_time"
                      defaultValue={slotListKey[1]}
                      onChange={this.handleUpdateTime.bind(this, item)}
                    >
                      <option value="">Select</option>
                      {this.getbreaktimelist(slotListKey[1], item, disableSlot)}
                    </select>
                  </div>
                </div>
                <span
                  data-tip={this.state.delivery_blocks}
                  data-html={true}
                  data-class="default_tooltip order_tooltip"
                  className="tooltip_link"
                >
                  <img src={warningone} alt="warning" />
                </span>
              </div>
            </div>
            <div className="products_info">
              {items.length > 0 ? this.cartItemlist(items) : ""}
            </div>
          </div>
        </div>
      );
    });
  }
  cartItemlist(cartItem) {
    const cartlsthtml = cartItem.map((cartLst, index) => (
      <div key={index} className="cart-package-info">
        <div className="products_info_item product-details">
          <div className="cart_left">
            <h4>
              <Link
                to="#"
                onClick={this.editCartItem.bind(this, cartLst, index)}
                className="card_edit"
              >
                <i className="fa fa-pencil-square-o"></i>
              </Link>
              {stripslashes(cartLst.cart_item_product_name)}
            </h4>
            <div className="products_info_options">
              <div className="products_info_options_wrap">
                {this.loadModifierItems(cartLst.set_menu_component)}
              </div>
            </div>
          </div>
          <div className="cart_right text-right">
            <span className="cart_indvl_price">
              {Parser(showPriceValue(cartLst.cart_item_total_price))}
            </span>
            <span className="counter cart-counter qty_bx">
              <span
                className="qty_minus cart_qty_minus"
                onClick={this.incQty.bind(
                  this,
                  cartLst.cart_item_id,
                  cartLst.cart_item_product_id,
                  cartLst.cart_item_min_qty,
                  cartLst.cart_item_qty,
                  "minus"
                )}
              >
                -
              </span>
              <strong className="product_quantity">
                {cartLst.cart_item_qty}
              </strong>
              <span
                className="qty_plus cart_qty_plus"
                onClick={this.incQty.bind(
                  this,
                  cartLst.cart_item_id,
                  cartLst.cart_item_product_id,
                  cartLst.cart_item_min_qty,
                  cartLst.cart_item_qty,
                  "plus"
                )}
              >
                +
              </span>
            </span>

          </div>

        </div>
        <div className="cart-settup-list">
          <ul>
           {cartLst.cart_item_special_notes !=='' && cartLst.cart_item_special_notes !=='undefined' && (
           <li>
           <div class="pkg-list-lhs">
           <label>Special Instruction:</label>
           </div>
           <div class="pkgslist_addition">
           <p>{cartLst.cart_item_special_notes}</p>
           </div>
           </li>
           )}

            {this.checkSettup(cartLst.setup, "Buffet") && (
              <li>
                <div className="pkg-list-lhs">
                  <label>Buffet:</label>
                  <div>
                    <span className="price">
                      {Parser(
                        showPriceValue(
                          this.settuptotalPrice(cartLst.setup, "Buffet")
                        )
                      )}
                    </span>
                    <a
                      className="remove cart_remove"
                      href="javascript:void(0)"
                      onClick={this.removeCartIndividual.bind(
                        this,
                        cartLst.cart_item_id,
                        "",
                        "setup",
                        "Buffet"
                      )}
                    >
                      <img src={crossImg} alt="" />
                    </a>
                  </div>
                </div>
                <div className="pkgslist_addition">
                  {this.loadSettup(cartLst.setup, "Buffet")}
                </div>
              </li>
            )}
            {this.checkSettup(cartLst.setup, "Delivery") && (
              <li>
                <div className="pkg-list-lhs">
                  <label>Delivery:</label>
                  <div>
                    <span className="price">
                      {Parser(
                        showPriceValue(
                          this.settuptotalPrice(cartLst.setup, "Delivery")
                        )
                      )}
                    </span>
                    <a
                      className="remove cart_remove"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.removeCartIndividual(
                          cartLst.cart_item_id,
                          "",
                          "setup",
                          "Delivery"
                        );
                      }}
                    >
                      <img src={crossImg} alt="" />
                    </a>
                  </div>
                </div>
                <div className="pkgslist_addition">
                  {this.loadSettup(cartLst.setup, "Delivery")}
                </div>
              </li>
            )}
            {this.checkSettup(cartLst.setup, "Self Collection") && (
              <li>
                <div className="pkg-list-lhs">
                  <label>Self Collection:</label>
                  <div>
                    <span className="price">
                      {Parser(
                        showPriceValue(
                          this.settuptotalPrice(
                            cartLst.setup,
                            "Self Collection"
                          )
                        )
                      )}
                    </span>
                    <a
                      className="remove cart_remove"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.removeCartIndividual(
                          cartLst.cart_item_id,
                          "",
                          "setup",
                          "Self Collection"
                        );
                      }}
                    >
                      <img src={crossImg} alt="" />
                    </a>
                  </div>
                </div>
                <div className="pkgslist_addition">
                  {this.loadSettup(cartLst.setup, "Self Collection")}
                </div>
              </li>
            )}
            {cartLst.equipment !== "" &&
              cartLst.equipment !== undefined &&
              cartLst.equipment.length > 0 && (
                <li>
                  <div className="pkg-list-lhs">
                    <label>Equipment:</label>
                    <div>
                      <span className="price">
                        {Parser(
                          showPriceValue(
                            this.settuptotalPrice(
                              cartLst.equipment,
                              "Equipment"
                            )
                          )
                        )}
                      </span>
                      <a
                        className="remove cart_remove"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.removeCartIndividual(
                            cartLst.cart_item_id,
                            "",
                            "equipment",
                            "equipment"
                          );
                        }}
                      >
                        <img src={crossImg} alt="" />
                      </a>
                    </div>
                  </div>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.equipment, "Equipment")}
                  </div>
                </li>
              )}
            {cartLst.addons_setup !== "" &&
              cartLst.addons_setup !== undefined &&
              cartLst.addons_setup.length > 0 && (
                <li>
                  <label>ADD-ONS:</label>
                  <div className="pkgslist_addition">
                    {this.loadSettup(cartLst.addons_setup, "Addons")}
                  </div>
                </li>
              )}
          </ul>
        </div>
      </div>
    ));
    return cartlsthtml;
  }

  checkSettup(setup, type) {
    let count = 0;
    setup.map((settup) => {
      if (settup.cart_setup_type === type) {
        count++;
      }
    });
    if (count > 0) {
      return true;
    } else {
      return false;
    }
  }
  loadSettup(setup, type) {
    let check_settup = this.checkSettup(setup, type);
    if(type !=="" && type !== undefined && type !== "undefined"){
      this.setState({ cartAvailbility: type});
    }
    if (type === "Equipment") {
      return setup.map((settup, index) => {
        return (
          <p key={index}>
            {settup.cart_equipment_qty} X {settup.cart_equipment_description} (+
            {settup.cart_equipment_price})
          </p>
        );
      });
    } else if (type === "Addons") {
      return setup.map((settup, index) => {
        return (
          <div className="pkgslist_additionrow" key={index}>
            <div className="pkg-list-lhs">
              <label>{settup.cart_addon_setup_title}</label>
              <div>
                <span className="price">
                  {Parser(showPriceValue(settup.cat_addon_setup_amount))}
                </span>
                <a
                  className="remove cart_remove"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.removeCartIndividual(
                      settup.cart_addon_setup_cart_item_id,
                      settup.cart_addon_setup_primary_key,
                      "addons_setup",
                      "addons_setup"
                    );
                  }}
                >
                  <img src={crossImg} alt="" />
                </a>
              </div>
            </div>
            <div className="pkgslist_subaddition">
              {this.loadAddonValues(settup.addons_setup_values)}
            </div>
          </div>
        );
      });
    } else {
      if (check_settup) {
        return setup.map((settup, index) => {
          if (settup.cart_setup_type === type) {
            return (
              <p key={index}>
                {settup.cart_setup_name} ({settup.cart_setup_price}){" "}
              </p>
            );
          }
        });
      }
    }
  }

  settuptotalPrice(setup, type) {
    let totalPrice = 0;
    if (type === "Equipment") {
      setup.map((settup) => {
        totalPrice += parseFloat(settup.cart_equipment_totalprice);
      });
    } else {
      setup.map((settup) => {
        if (settup.cart_setup_type === type) {
          totalPrice += parseFloat(settup.cart_setup_tatalprice);
        }
      });
    }
    return totalPrice;
  }

  loadAddonValues(AddonValues) {
    return AddonValues.map((addonval, index) => {
      return (
        <p key={index}>
          {addonval.cart_addon_setup_val_title} (
          {addonval.cart_addon_setup_val_qty}X)
        </p>
      );
    });
  }

  loadModifierItems(combo) {
    if (combo.length > 0) {
      return combo.map((item, index) => {
        return (
          <span key={index}>
            <b>{item.menu_component_name}</b>
            <div>{this.showComboProducts(item.product_details)}</div>
          </span>
        );
      });
    }
  }
  showComboProducts(product) {
    if (product.length > 0) {
      return product.map((item, index) => {
        let newPrice =
          item.cart_menu_component_product_price > 0
            ? " (+" + item.cart_menu_component_product_price + ")"
            : "";
        return (
          <>
            <p key={index}>
              {item.cart_menu_component_product_qty} X{" "}
              {item.cart_menu_component_product_name} {newPrice}
            </p>
            {item.modifiers.length > 0
              ? this.showComboModifiers(item.modifiers)
              : ""}
            {item.custom_text !== "" && (
              <div>
                <label className="title">Custom Title</label>
                <div className="products_info_options">
                  <p>{item.custom_text}</p>
                </div>
              </div>
            )}
            {item.custom_logo !== "" && (
              <div>
                <label className="title">Logo</label>
                <div className="products_info_options">
                  <p>{item.custom_logo}</p>
                </div>
              </div>
            )}
          </>
        );
      });
    }
  }
  showComboModifiers(modifiers) {
    if (modifiers.length > 0) {
      return modifiers.map((item, index) => {
        return (
          <div className="modifier_values_list_wrap" key={index}>
           {/*<p>{item.cart_modifier_name}</p>*/}
            {item.modifiers_values.length > 0 && (
              <div className="modifier_values_list">
                {item.modifiers_values.map(function (item1, index1) {
                  let modifier_value_price =
                    parseFloat(item1.cart_modifier_price) > 0
                      ? "(" + item1.cart_modifier_price + ")"
                      : "";
                  return (
                    <p key={index1}>
                      {item1.cart_modifier_name}&nbsp;{modifier_value_price}
                    </p>
                  );
                })}
              </div>
            )}
          </div>
        );
      });
    }
  }

  selectCurrentSlot(currentSel) {
    if (currentSel === "") {
      currentSel = 0;
    }
    this.setState({ currentSlot: currentSel }, function () {
      this.getCartDetList();
    });
  }
  incQty(cartItemId, productId, item_min_qty, item_qty, type) {
    let paxIncrease = 10;
    if (type === "plus") {
      item_qty = parseInt(item_qty) + parseInt(paxIncrease);
    } else if (type === "minus") {
      item_qty = parseInt(item_qty) - parseInt(paxIncrease);
    }
    if (item_qty >= item_min_qty) {
      var postObject = {};
      postObject = {
        app_id: appId,
        cart_item_id: cartItemId,
        product_id: productId,
        product_qty: item_qty,
        cartAction: "update",
      };
      if (typeof cookie.load("UserId") === "undefined") {
        postObject["reference_id"] = getReferenceID();
      } else {
        postObject["customer_id"] = cookie.load("UserId");
      }
      var currentObject = this;
      showLoader("catering_cart_list", "class");
      axios
        .post(apiUrlCtrng + "cart/update", qs.stringify(postObject))
        .then((res) => {
          hideLoader("catering_cart_list", "class");
          if (res.data.status === "ok") {
            swal("Success!", res.data.message, "success");
            currentObject.loadCartDet();
          } else if (res.data.status === "error") {
            swal("Error!", res.data.message, "error");
          }
        });
    }
  }
  removeCartIndividual(cartItmId, cartPryId, type, setupType = "") {
    if (
      cartItmId !== "" &&
      (cartPryId !== "" ||
        type === "setup" ||
        type === "equipment" ||
        type === "addons_setup") &&
      type !== ""
    ) {
      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to delete this item?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (cartItmId) {
            showLoader("catering_cart_list", "class");
            var postCrtObject = {};
            postCrtObject = {
              app_id: appId,
              cart_item_id: cartItmId,
              p_id: cartPryId,
              type: type,
              setupType: setupType,
            };
            if (typeof cookie.load("UserId") === "undefined") {
              postCrtObject["reference_id"] = getReferenceID();
            } else {
              postCrtObject["customer_id"] = cookie.load("UserId");
            }
            showLoader("catering_cart_list", "class");
            axios
              .post(
                apiUrlCtrng + "cart/delete_individual",
                qs.stringify(postCrtObject)
              )
              .then((res) => {
                hideLoader("catering_cart_list", "class");
                if (res.data.status === "ok") {
                  this.loadCartDet();
                  swal(
                    "Success!",
                    "Nice! Products updated to your cart",
                    "success"
                  );
                } else {
                  swal(
                    "Error!",
                    "Sorry! Products can`t update your cart",
                    "error"
                  );
                }
              })
              .catch(function (error) {
                swal("Error!", "something went wrong", "error");
                hideLoader("catering_cart_list", "class");
              });
          } else {
            swal("Error!", "You haven’t added any items yet !", "error");
          }
        }
      });
    }
  }
  deleteCartItm(cart_item_id, checkalert) {
    if (checkalert === "alert") {
      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to delete this item?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (cart_item_id) {
            showLoader("catering_cart_list", "class");
            this.props.getDeleteCateringCart(cart_item_id);
          } else {
            swal("Error!", "You haven’t added any items yet !", "error");
          }
        }
      });
    } else {
      if (cart_item_id) {
        this.props.getDeleteCateringCart(cart_item_id);
      } else {
        swal("Error!", "You haven’t added any items yet !", "error");
      }
    }
  }
  deleteTempSlot() {
    $(".addition-breaktime").hide().removeAttr("id");
    this.setState({ addBreakTime: 0, currentSlot: 0 });
  }
  handleUpdateTime(slotKey, e) {
    this.setState({ [e.target.name]: e.target.value }, function () {
      if (this.state.start_time !== "" && this.state.end_time !== "") {
        this.updateBreakTime(
          slotKey,
          this.state.start_time,
          this.state.end_time
        );
      }
    });
  }
  updateBreakTime(cartItmId, startTime, endTime) {
    var itemList = this.state.cart_items[cartItmId];
    var updateItem = "";
    for (let item in itemList) {
      var itemAssign = itemList[item];
      updateItem += itemAssign.cart_item_id;
      if (item != itemList.length - 1) {
        updateItem + "~~";
      }
    }
    var postCrtObject = {};
    postCrtObject = {
      app_id: appId,
      cart_item_id: updateItem,
      break_time: startTime,
      break_time_end: endTime,
    };
    if (typeof cookie.load("UserId") === "undefined") {
      postCrtObject["reference_id"] = getReferenceID();
    } else {
      postCrtObject["customer_id"] = cookie.load("UserId");
    }
    axios
      .post(apiUrlCtrng + "cart/updateBreakTime", qs.stringify(postCrtObject))
      .then((res) => {
        if (res.data.status === "ok") {
          this.loadCartDet();
          this.setState({ start_time: "", end_time: "" });
          swal("Success!", "Nice! Slot updated to your cart", "success");
        } else {
          swal("Error!", "Sorry! Slot can`t update your cart", "error");
        }
      })
      .catch(function (error) {
        swal("Error!", "something went wrong", "error");
      });
  }
  downloadCartItem() {
    var customerId = cookie.load("UserId");
    var customerEmail = cookie.load("UserEmail");
    if (!customerEmail) {
      window.$.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });

      cookie.save("downloadQuote", "yes", { path: "/" });
      return false;
    }
    var comSettings = this.state.settings;
    var gstPar = 0;
    if (Object.keys(comSettings).length > 0) {
      gstPar =
        comSettings.client_tax_surcharge !== ""
          ? parseFloat(comSettings.client_tax_surcharge)
          : 0;
    }
    var outletIdVl = cookie.load("orderOutletId");
    showLoader("downalod-quote", "class");
    var urlParam =
      "&customer_id=" +
      customerId +
      "&request_email=" +
      customerEmail +
      "&client_gst_charge=" +
      gstPar +
      "&promotion=" +
      "&outlet_id=" +
      this.state.outlet_id;
    $(".download_quote").addClass("loading loading-20");
    axios
      .get(apiUrlCtrng + "catering/downloadQtnItems?app_id=" + appId + urlParam)
      .then((res) => {
        hideLoader("downalod-quote", "class");
        if (res.data.status === "ok") {
          swal("Success", res.data.message, "success");
        } else {
          swal("Error", res.data.message, "error");
        }
        cookie.remove("downloadQuote");
        $(".download_quote").removeClass("loading loading-20");
      });
  }
  getImageName = () => (this.state.open ? delete_dark : delete_white);
  getImageNameAlt = () => (this.state.open ? delete_white : delete_dark);

  checkPostalcode(e) {
    e.preventDefault();
    var validate = true;
    if (this.state.postal_code === "") {
      this.setState({ postalcode_error: 1 }, function () {
        $("html, body").animate(
          {
            scrollTop: $("#postal_code").offset().top - 150,
          },
          500
        );
        $("#postal_code").focus();
      });
      validate = false;
    } else {
      this.setState({ postalcode_error: "" });
    }
    if (validate == true) {
      if (this.state.orderDate === "") {
        this.setState({ orderdate_error: 1 }, function () {
          $("html, body").animate(
            {
              scrollTop: $(".datetime-wrap").offset().top - 150,
            },
            500
          );
        });
        validate = false;
      } else {
        this.setState({ orderdate_error: "" });
      }
    }

    if (validate === true) {
      $("body").removeClass("cart-items-open");
      this.props.history.push("/catering-checkout");
    }
  }

  editCartItem(cartLst, cartItemKey, e) {
    e.preventDefault();
    cookie.save("cat_Slug", this.props.match.params.catSlug, { path: "/" });
    cookie.save("subCat_Slug", this.props.match.params.subCatSlug, {
      path: "/",
    });
    window.location.href =
      baseUrl +
      "catering/edit/" +
      cartLst.cart_item_product_id +
      "/" +
      cartItemKey;
  }

  render() {
    const imageName = this.getImageName();

    var gstPar = 0;
    var grandTotalAmt = this.state.cartDetailsMain
      ? parseFloat(this.state.cartDetailsMain.cart_grand_total)
      : 0;
    var zoneDetails = this.state.zoneDetails;

    var comSettings = this.state.settings;
    var zone_order_value_delivery_charge = 0;
    if (comSettings && Object.keys(comSettings).length > 0) {
      if (comSettings.zone_order_value_delivery_charge === "1") {
        zone_order_value_delivery_charge = 1;
      }
    }
    var zone_delivery_charge = 0;

    if (zone_order_value_delivery_charge === 1) {
      zone_delivery_charge = this.state.cart_zone_delivery_charge;
    } else {
      zone_delivery_charge =
        zoneDetails.zone_delivery_charge !== "" &&
        typeof zoneDetails.zone_delivery_charge !== undefined
          ? parseFloat(zoneDetails.zone_delivery_charge)
          : 0;
    }
    var zone_additional_delivery_charge =
      zoneDetails.zone_additional_delivery_charge !== "" &&
      typeof zoneDetails.zone_additional_delivery_charge !== undefined
        ? parseFloat(zoneDetails.zone_additional_delivery_charge)
        : 0;
    if (zone_delivery_charge > 0 || zone_additional_delivery_charge > 0) {
      grandTotalAmt =
        parseFloat(grandTotalAmt) +
        parseFloat(zone_delivery_charge) +
        parseFloat(zone_additional_delivery_charge);
    }

    var surcharge =
      this.state.surcharge !== "" && typeof this.state.surcharge !== 'undefined' && typeof this.state.surcharge !== null
        ? parseFloat(this.state.surcharge)
        : 0;

    let {
      sur_deliverysetup_fee,
      sur_ear_deliverysetup_fee,
      sur_lat_teardown_fee,
      sur_deliverysetup_fee_name,
      sur_ear_deliverysetup_fee_name,
    } = getSurchargesplitup(surcharge, this.state.surchargeLog);

    if (surcharge > 0) {
      grandTotalAmt = parseFloat(grandTotalAmt) + parseFloat(surcharge);
    }

    if (comSettings && Object.keys(comSettings).length > 0) {
      gstPar =
        comSettings.client_tax_surcharge !== ""
          ? parseFloat(comSettings.client_tax_surcharge)
          : 0;
      var gstAmount = getGstValue(gstPar, grandTotalAmt, "format");
      var gstAmountTxt = getGstValue(gstPar, grandTotalAmt, "");
      grandTotalAmt = parseFloat(grandTotalAmt) + parseFloat(gstAmountTxt);
    }

    //console.log(this.state.zoneDetails, "zoneDetails");

    return (
      <>
        <Header cartcount={this.state.cartcount} />
        <div className="product_container">
          <div className="common-inner-blckdiv">
            <div className="common-inner-banner">
              <h1>Our Catering Menu</h1>
            </div>
          </div>
          <section>
            <div className="search-product">
              <div className="container">
                {/*<div className="search-product-main">
                  <div className="backto-rest">
                    <Link to={"/"}>
                      {" "}
                      <img src={gridimg} alt="" />
                    </Link>
                  </div>
                  <div className="spm-lhs">
                    <input
                      type="text"
                      className="search-rest"
                      id="searchKeyWord"
                      placeholder="Search for food..."
                    />
                    <img src={searchimg} alt="" />
                     <ul
                      className="product_search_result"
                      id="product_search_result"
                    >
                    </ul>
                  </div>
                  <div className="spm-rhs">
                    <div className="filter-area">
                      Filters
                      <img src={filterImg} alt="" className="filter-img" />
                      <img
                        src={closebl}
                        alt=""
                        className="close_filter_section filter-clear-image"
                        style={{ display: "none" }}
                      />
                    </div>
                    <div className="filter-details" style={{ display: "none" }}>
                      {this.state.filterTag.length > 0 && (
                        <div className="clear-filter">
                          <Link to="#">Clear</Link>
                        </div>
                      )}
                      <div className="item-filter common-filter">
                        <h3 className="filter-tag">Tag</h3>
                        {<ul>{this.state.tagList}</ul>}
                      </div>
                    </div>
                  </div>
                </div>*/}
                {/*<div className="product-lo-top">
                  <a
                    className="active"
                    href="#"
                    onClick={this.chooseAvailabilityFun.bind(this, cateringId)}
                  >
                    <span>Catering</span>
                  </a>
                  <a
                    href="#"
                    onClick={this.chooseAvailabilityFun.bind(this, deliveryId)}
                  >
                    <span>Delivery/Pickup</span>
                  </a>
                </div> */}
              </div>
            </div>
          </section>
          <section className="product-menu-listing">
            <div className="container catering-product-list">
               <Category {...this.props} />
              <div className="product-lhs">
                <div className="product-lhs-inner">
                  <Product
                    {...this.props}
                    productState={this.state}
                    sateValChange={this.sateValChange}
                  />
                </div>
              </div>
              <div className="product-rhs">
                <div className="hcart_dropdown">
                  <div className="product_orders_top">
                    <h4>My Cart</h4>
                    <span className="close_product_orders">
                      {" "}
                      <img
                        src={crossImg}
                        alt=""
                        onClick={this.closeCartlist.bind(this)}
                      />
                    </span>
                  </div>
                  <div className="product_orders cart_body catering_cart_list">
                    <div className="product_delivery product-card">
                      <div className="del_address">
                        <h5 id="self_collection_text">Delivery Address</h5>
                        <div className="form-group">
                          <div className="input_field">
                            <input
                              type="text"
                              placeholder="Postal Code"
                              className="form-control"
                              id="postal_code"
                              maxLength={6}
                               value={
                                  cookie.load("catering_postal_code") !== "undefined"
                                    ? cookie.load("catering_postal_code")
                                    : this.state.catering_postal_code
                                }
                              onChange={this.changePostalCode.bind(this)}
                              onKeyPress={(e) => validateIntval(e)}
                            />
                            {this.state.postalcode_error === 1 && (
                              <span className="error error_scroll">
                                Please enter valid postal code!
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="input_field">
                            <input
                              type="text"
                              placeholder="Address Line 1"
                              className="form-control"
                              value={this.state.catering_address_line}
                              readOnly={true}
                            />
                          </div>
                        </div>
                        <div className="form-group half-fg">
                          <div className="input_field">
                            <input
                              type="text"
                              placeholder="Unit Number 1"
                              className="form-control"
                              name="unit_number1"
                              defaultValue={this.state.unit_number1}
                              onChange={this.handleAddrChange.bind(this)}
                            />
                          </div>

                          <div className="input_field">
                            <input
                              type="text"
                              placeholder="Unit Number 2"
                              className="form-control"
                              name="unit_number2"
                              defaultValue={this.state.unit_number2}
                              onChange={this.handleAddrChange.bind(this)}
                            />
                          </div>
                        </div>
                        <h5>Event Date</h5>
                        <div className="form-group datetime-wrap">
                          <div className="input_field">
                            <DatePicker
                              selected={
                                this.state.orderDate !== ""
                                  ? new Date(this.state.orderDate)
                                  : ""
                              }
                              onChange={this.handleChange}
                              className="form-control order_date"
                              placeholderText="Event Date"
                              excludeDates={this.state.disable_dates}
                              minDate={this.state.mindate}
                              maxDate={this.state.maxdate}
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                          {this.state.orderdate_error !== "" && (
                            <span className="error order_date_error error_scroll">
                              Please select order date!
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="order_det">
                        {this.state.cartDisplay !== ""
                          ? this.state.cartDisplay
                          : ""}
                        {this.state.addBreakTime === 1 ? (
                          <div className="order_card dark addition-breaktime">
                            <div
                              className="order_card_head"
                              onClick={this.selectCurrentSlot.bind(this, "")}
                            >
                              <h5>Order Details </h5>
                              <Link
                                onClick={this.deleteTempSlot.bind(this)}
                                to="#"
                              >
                                <img src={imageName} alt="delete" />
                              </Link>
                            </div>
                            <div className="order_card_body">
                              <div className="event_timing">
                                <div className="form-group">
                                  <div className="input_field">
                                    <label>Event Start Time</label>
                                    <div className="custom_select">
                                      <select
                                        className="form-control start_time"
                                        name="start_time"
                                        onChange={this.handleUpdateSlotTime.bind(
                                          this
                                        )}
                                      >
                                        <option value="">Select</option>
                                        {this.getbreaktimelist(
                                          "",
                                          "",
                                          this.state.disableSlot
                                        )}
                                      </select>
                                      {this.state.startslot_error === 1 && (
                                        <span className="error start_time_error">
                                          This Field is required!
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <span
                                    data-tip={this.state.delivery_blocks}
                                    data-html={true}
                                    data-class="default_tooltip order_tooltip"
                                    className="tooltip_link"
                                  >
                                    <img src={warningone} alt="warning" />
                                  </span>
                                </div>
                                <div className="form-group">
                                  <div className="input_field">
                                    <label>Event End Time</label>
                                    <div className="custom_select">
                                      <select
                                        className="form-control end_time"
                                        name="end_time"
                                        onChange={this.handleUpdateSlotTime.bind(
                                          this
                                        )}
                                      >
                                        <option value="">Select</option>
                                        {this.getbreaktimelist(
                                          "",
                                          "",
                                          this.state.disableSlot
                                        )}
                                      </select>
                                      {this.state.endslot_error === 1 && (
                                        <span className="error end_time_error">
                                          This Field is required!
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <span
                                    data-tip={this.state.delivery_blocks}
                                    data-html={true}
                                    data-class="default_tooltip order_tooltip"
                                    className="tooltip_link"
                                  >
                                    <img src={warningone} alt="warning" />
                                  </span>
                                </div>
                              </div>
                              <div className="products_cart text_center">
                                <img src={cart_blue} alt="cart" />
                                <span>You haven’t added any items yet !</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="text_center">
                            <Link
                              className="link add-breaktime"
                              to="#"
                              onClick={(e) => {
                                e.preventDefault();
                                this.addBreakTime();
                              }}
                            >
                              {this.state.cartDetailsMain
                                ? "Add Another Break Time & Order"
                                : "Select Event Time"}
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {this.state.cartDetailsMain !== "" && (
                    <>
                      <div className="products_total product-card cart_footer">
                        <div className="cart_footer_inner_item">
                        <div className="products_total_item cart_row">
                          <p className="text-uppercase">Subtotal</p>
                          <span className="total_cart_price">
                            {this.state.cartDetailsMain
                              ? Parser(
                                  showPriceValue(
                                    this.state.cartDetailsMain.cart_grand_total
                                  )
                                )
                              : ""}
                          </span>
                        </div>
                        {this.state.zoneDetails !== "" && Object.keys(this.state.zoneDetails).length > 0 && (
                          <div className="products_total_item cart_row">
                            <p className="text-uppercase">Location surcharge</p>
                            <span>
                              {Parser(
                                showPriceValue(
                                  parseFloat(zone_delivery_charge) +
                                    parseFloat(zone_additional_delivery_charge)
                                )
                              )}
                            </span>
                          </div>
                        )}
                        {parseFloat(this.state.surcharge) > 0 &&
                          sur_deliverysetup_fee > 0 && (
                            <div className="products_total_item cart_row">
                              <p className="text-uppercase">
                                Delivery & setup fee{" "}
                                {sur_deliverysetup_fee_name}
                              </p>
                              <span>
                                {Parser(showPriceValue(sur_deliverysetup_fee))}
                              </span>
                            </div>
                          )}
                        {parseFloat(this.state.surcharge) > 0 &&
                          sur_ear_deliverysetup_fee > 0 && (
                            <div className="products_total_item cart_row">
                              <p className="text-uppercase">
                                Early setup fee / Late setup fee{" "}
                                {sur_ear_deliverysetup_fee_name}
                              </p>
                              <span>
                                {Parser(
                                  showPriceValue(sur_ear_deliverysetup_fee)
                                )}
                              </span>
                            </div>
                          )}
                        {parseFloat(this.state.surcharge) > 0 &&
                          sur_lat_teardown_fee > 0 && (
                            <div className="products_total_item cart_row">
                              <p className="text-uppercase">
                                Late teardown fee
                              </p>
                              <span>
                                {Parser(showPriceValue(sur_lat_teardown_fee))}
                              </span>
                            </div>
                          )}
                        {parseFloat(gstPar) > 0 && (
                          <div className="products_total_item cart_row gst-row">
                            <p className="text-uppercase">GST ({gstPar}%)</p>
                            <span>{Parser(showPriceValue(gstAmount))}</span>
                          </div>
                        )}
                        <div className="products_total_item products_total_amount cart_row grant-total-cls">
                          <p className="text-uppercase">Total </p>
                          <span>{Parser(showPriceValue(grandTotalAmt))}</span>
                        </div>
                        <div className="fixed_checkout">
                          <Link
                            to="/"
                            className="btn btn_pink cartaction_checkout"
                            onClick={this.checkPostalcode.bind(this)}
                          >
                            Continue to Checkout
                          </Link>
                        </div>
                        </div>
                      </div>
                      <div className="text_center downalod-quote">
                        <Link
                          className="link download_quote"
                          to="#"
                          onClick={this.downloadCartItem.bind(this)}
                        >
                          Download Quotation
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer {...this.props} />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getCategoryList: () => {
      dispatch({ type: GET_CATEGORY_LIST });
    },
    getCateringProductList: (parameter) => {
      dispatch({ type: GET_CATERING_PRODCUTLIST, parameter });
    },
    getStaticBlock: (staticblock_list) => {
      dispatch({
        type: GET_STATIC_BLOCK,
        staticblock_list:
          "footer-contact-info,footer-copyright-info,package-banner,popup-disclaimer,delivery-charge-calculation",
      });
    },
    updateCartDetail: (productId, cartItemId, cartQty) => {
      dispatch({ type: UPDATE_CART_DETAIL, productId, cartItemId, cartQty });
    },
    getCarttimedata: (obj) => {
      dispatch({ type: GET_CARTTIMEDATA, obj: obj });
    },
    getCartdatedata: (obj) => {
      dispatch({ type: GET_CARTDATEDATA, obj: obj });
    },
    /* getAvailabileDateList: (obj) => {
      dispatch({ type: GET_AVAILABLE_DATE, obj: obj });
    }, */
    getCartList: (obj) => {
      dispatch({ type: GET_CARTLIST, obj });
    },
    getDeleteCateringCart: (cartItemId) => {
      dispatch({ type: GET_DELETE_CATERING_CART, cartItemId });
    },
    GetUploadImage: (obj) => {
      dispatch({ type: GET_UPLOAD_IMAGE, obj });
    },
    getDeleteImage: (obj) => {
      dispatch({ type: GET_DELETE_IMAGE, obj });
    },
    getCateringCartDetail: () => {
      dispatch({ type: GET_CATERINGCART_DETAIL });
    },
  };
};

const mapStateToProps = (state) => {
  let productData = [];
  if (state.cateringprodcutlist.length > 0) {
    productData = state.cateringprodcutlist;
  }
  return {
    categoryData: state.categorylist,
    productData: productData,
    staticblockData: state.staticblack,
    cartdate: state.cartdate,
    carttime: state.carttime,
    cartlist: state.cartlist,
    deleteDetail: state.deleteCateringCart,
    uploadDataImage: state.uploadDataImage,
    deleteDataImage: state.deleteDataImage,
  };
};

Index = connect(mapStateToProps, mapDispatchToProps)(Index);

export default withRouter(Index);
