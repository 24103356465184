/* eslint-disable */
import React, { Component } from "react";
import { validated } from "react-custom-validation";
import axios from 'axios';
import validator from "validator";
import { appId, apiUrl } from "../Helpers/Config";
const isEmpty = (value) => (value === "" ? "This field is required." : null);

const isEmail = (email) =>
validator.isEmail(email) ? null : 'This is not a valid email.'

const phonenumberPattern = /^[0-9]{6,8}$/;
const isMobile = (mobile) =>
    mobile.match(phonenumberPattern) ? null : 'please enter valid Phone number.'

function validationConfigCheckout(props) {
    const { check_firstname, check_lastname, check_email, check_phone } = props.fields
    return {
        fields: ['firstname', 'email', 'mobile'],

        validations: {
            firstname: [
                [isEmpty, check_firstname]
            ],
            email: [
                [isEmail, check_email]
            ],
            mobile: [
                [isEmpty, check_phone],
                [isMobile, check_phone]
            ]
        }
    }
}


class Guestcheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
        status: 'Loading',
        check_phone : ''
    };  
  }


  componentDidMount() {
      var check_phone = this.props.fields.check_phone;
      this.setState({'check_phone': check_phone});
  }

  componentWillReceiveProps(nextProps) {
      this.setState({'check_phone': nextProps.fields.check_phone});
  }

  isGuestPhonenumerExist(){
      var mobile = $("#check_phone").val();  
      this.setState({'check_phone' : mobile });
      this.props.onChange('check_phone',mobile);
      if(mobile.length > 7){
          axios.get(apiUrl + "guestaccount/guest_customer_phone?app_id="+appId+"&status=A&phone_no="+mobile).then(response => {
              if (response.data.status === "ok") {
                  this.props.onChange('check_phone',mobile);
                 // this.props.onChange('check_firstname', response.data.result_set.customer_first_name);
                 // this.props.onChange('check_email', response.data.result_set.customer_email);
              }else{
                  this.props.onChange('check_phone',mobile);
                  /*this.props.onChange('check_firstname', '');
                  this.props.onChange('check_lastname', '');
                  this.props.onChange('check_email', ''); */
              }
          });
      }else{
          this.setState({'check_phone': mobile});
      }
  }

  render() {
    const {
      fields,
      onChange,
      onValid,
      onInvalid,
      $field,
      $validation,
    } = this.props;


     let errMsgFirstName, errMsgEmail,errMsgMobile;

    if ($validation.firstname.error.reason !== undefined) {
        errMsgFirstName = ($validation.firstname.show && <span className="error">{$validation.firstname.error.reason}</span>)
    }
    if ($validation.email.error.reason !== undefined) {
        errMsgEmail = ($validation.email.show && <span className="error">{$validation.email.error.reason}</span>)
    }
    if ($validation.mobile.error.reason !== undefined) {
        errMsgMobile = ($validation.mobile.show && <span className="error">{$validation.mobile.error.reason}</span>)
    }

    return (
      <div className="popup-body">
        <span className="spn-error"></span>
        <div className="form-group">
          <div className="focus-out">
            <label>Enter Mobile Number</label>
            <input type="tel" maxLength="8" id="check_phone" className="form-control input-focus" pattern="\d*" value={this.state.check_phone} onChange ={()=>this.isGuestPhonenumerExist()} />
            {errMsgMobile}
          </div>
        </div>

        <div className="form-group">
          <div className="focus-out">
            <label>Enter Your Name</label>

          <input type="text" className="form-control input-focus" value={fields.check_firstname} {...$field('check_firstname', (e) => onChange('check_firstname', e.target.value)) } />

            {errMsgFirstName}
          </div>
        </div>

        <div className="form-group">
          <div className="focus-out">
            <label>Enter Your Email</label>
            <input type="text" className="form-control input-focus" value={fields.check_email} {...$field('check_email', (e) => onChange('check_email', e.target.value)) } />
            {errMsgEmail}
          </div>
        </div>

        <span className="pdpa_trmcont_link">By Continuing you agree to the <a className="sign_reg_uline" target="blank" href="/terms-conditions">PDPA Terms and conditions</a>.</span>
    

        <div className="form-group">
          <div className="login_pop_sub">
            <button
              className="btn btn_black btn_minwid login_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              {" "}
              Submit{" "}
            </button>
          </div>
        </div>

        <div className="form-group">
          <div className="controls two-links">
            <a
              href="#signup-popup"
              data-effect="mfp-zoom-in"
              className="open-popup-link"
            >
              {" "}
              Create an account
            </a>
            <span className="spilter"> </span>
            <a href="#forgot-password-popup" className="open-popup-link">
              Forgot Pin
            </a>{" "}
          </div>
        </div>
      </div>
    );
  }
}
Guestcheckout = validated(validationConfigCheckout)(Guestcheckout);

export default Guestcheckout;
