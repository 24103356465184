/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import cookie from "react-cookies";
import footerLogo from "../../common/images/logo.png";
import Qrcode from "../../common/images/qr-code.jpg";
import whatsimg from "../../common/images/green-whatsapp.png";
import location from "../../common/images/placeholder.png";
import telephone from "../../common/images/telephone.png";
import facebook from "../../common/images/facebook.png";
import instagram from "../../common/images/instagram.png";
import tiktok from "../../common/images/tik-tok.png";
import email from "../../common/images/email.png";

import footerscan from "../../common/images/footerscan.jpeg";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";

import { GET_STATIC_BLOCK } from "../../actions";
var Parser = require("html-react-parser");
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = { footerBlocks: "", footerlink: "" };
  }

  componentDidMount() {
    this.props.getStaticBlock();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "footer-contents") {
            footerPas = data.staticblocks_description;
            return "";
          }
        });
      }
      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: PropsData.staticblack,
        footerlink: footerPas,
      });
    }
  }

  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

   $(document).on('click','.new_login_popup',function(){
     $.magnificPopup.open({
     items: {
      src: '#login-popup'
     },
     type: 'inline'
      });
   });

    return ( <footer className="footer-main">
      <div className="footer-nav-info">
      <div className="container-full">
         <div className="foot-row">
         
            <div className="foot-sub-col-row">
              
               <div className="foot-col-add">
                  <div className="foot-col-row">
                     <div className="foot-col-ico">
                        <img src="https://ccpl.ninjaos.com/media/ckfinder/images/placeholder.png" />
                     </div>
                     <div className="foot-col-txt">
                        <h5>
                           Address
                        </h5>
                        <p>
                           15 Jalan Tepong, #04-17, Jurong Food Hub, Singapore 619336
                        </p>
                     </div>
                  </div>
                  <div className="foot-col-row foot-row-link">
                     <div className="foot-col-ico">
                        <img src="https://ccpl.ninjaos.com/media/ckfinder/images/telephone.png" />
                     </div>
                     <div className="foot-col-txt">
                        <h5>
                           Telephone
                        </h5>
                        <p>
                           <a className="foot-link" href="tel: 90010684">9001 0684</a> /<a className="foot-link" href="tel: 62657488">6265 5488</a>
                        </p>
                     </div>
                  </div>
                  <div className="foot-col-row foot-row-link">
                     <div className="foot-col-ico">
                        <img src="https://ccpl.ninjaos.com/media/ckfinder/images/email-semmys.png" />
                     </div>
                     <div className="foot-col-txt">
                        <h5>
                           Email
                        </h5>
                        <p>
                           <a className="foot-link" href="mailto:ask_us@semmyscatering.com">ask_us@semmyscatering.com</a>
                        </p>
                     </div>
                  </div>

               </div>
               <div className="foot-total-col">
                  <div className="foot-col-link">
                     <h5>
                        About Us
                     </h5>
                     <ul className="foot-sub-link">
                        <li>
                           <a href="/about-us">About Semmy's</a>
                        </li>
                        <li>
                           <a>Gallery</a>
                        </li>
                        <li>
                           <a href="/location">Location</a>
                        </li>
                        <li>
                           <a href="/contact-us">Contact Us</a>
                        </li>
                     </ul>
                  </div>
                  <div className="foot-col-link">
                     <h5>
                        My Account
                     </h5>
                     <ul className="foot-sub-link">

                     {!cookie.load("UserId") && (
                      <li> <a className="open-popup-link htico_sign new_login_popup" data-effect="mfp-zoom-in" 
                      href="javascript:;" title="Personal Details"><span>Personal details</span></a></li>
                      )}

                     {cookie.load("UserId") && (
                      <li><a href="/myaccount">Personal Details</a></li>
                     )}

                     {!cookie.load("UserId") && (
                      <li> <a className="open-popup-link htico_sign new_login_popup" data-effect="mfp-zoom-in" 
                      href="javascript:;" title="Orders"><span>Orders</span></a></li>
                      )}
    
                     {cookie.load("UserId") && (
                     <li><a href="/myorders">Orders</a></li>
                     )}
                     </ul>

                     {/*<div className="foot-top desktop-scan">
                      <div className="foot-scan">
                        <a href="" target="_blank" title="Semmy Catering"> 
                        <img src={footerscan} alt="Logo" /></a>
                      </div>
                    </div>*/}

                  </div>
                  <div className="foot-col-link">
                     <h5>
                        Other
                     </h5>
                     <ul className="foot-sub-link">
                        <li>
                           <a href="/privacy-policy">Privacy Policy</a>
                        </li>
                        <li>
                           <a href="/terms-conditions">Terms &amp; Conditions</a>
                        </li>
                        <li>
                           <a href="/faqs">FAQ</a>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
            <div className="foot-col-social">
               <img className="foot-logo" src="https://ccpl.ninjaos.com/media/dev_team/company-logo/78b4b06441672ce6170a5df7e73da754.png" />
               <h5>
                  Follow Us
               </h5>
               <ul className="foot-soc-link">
                  <li>
                     <a href="https://www.facebook.com/semmyscatering" target="_blank"><img src="https://ccpl.ninjaos.com/media/ckfinder/images/facebook-semmys.png" /></a>
                  </li>
                  <li>
                     <a href="https://www.instagram.com/semmyscatering/" target="_blank"> <img src="https://ccpl.ninjaos.com/media/ckfinder/images/instagram - semmys.png" /> </a>
                  </li>
               </ul>

               {/*<div className="foot-top mobile-scan">
                <div className="foot-scan">
                  <a href="" target="_blank" title="Semmy Catering"> 
                  <img src={footerscan} alt="Logo" /></a>
                </div>
              </div>*/}


            </div>
            
         </div>
      </div>
   </div>
   <div className="copyright-section">
      <p>Copyright 2022 Semmy's Catering. All rights reserved. Design By Jankosoft</p>
   </div>
   <div className="scrolltop show" id="scrollbutton"><a href="/" className="disbl_href_action"><span><i className="fa fa-angle-up"></i></span></a></div>
</footer>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
