import settings from "./settings";
import staticblack from "./staticblack";
import cartlistdetail from "./cartlistdetail";
import outlets from "./outlets";
import alloutlets from "./alloutlets";
import zonedetail from "./zonedetail";
import banner from "./banner";
import featureproduct from "./featureproduct";
import product from "./product";
import productdetail from "./productdetail";
import addonproduct from "./addonproduct";
import login from "./login";
import fblogin from "./fblogin";
import customerdetail from "./customerdetail";
import forgetpassword from "./forgetpassword";
import registration from "./registration";
import changepassword from "./changepassword";
import updatecustomerprofile from "./updatecustomerprofile";
import corderdetail from "./corderdetail";
import porderdetail from "./porderdetail";
import orderdetail from "./orderdetail";
import printorder from "./printorder";
import orderhistory from "./orderhistory";
import promotionlist from "./promotionlist";
import promotionreceipt from "./promotionreceipt";
import applypromotion from "./applypromotion";
import activitycount from "./activitycount";
import rewardearned from "./rewardearned";
import rewardredeem from "./rewardredeem";
import pagedata from "./pages";
import cateringorder from "./cateringorder";
import cateringpast from "./cateringpast";
import cateringhistory from "./cateringhistory";
import menudata from "./menudata";
import contactdata from "./contactdata";
import newsdata from "./news";
import cateringprint from "./cateringprint";
import holidays from "./holidays";
import categorylist from "./cateringcategory";
import subcategorylist from "./cateringsubcategory";
import ownvenuecategory from "./cateringownvenuecategory";
import hallcategory from "./cateringhallcategory";
import cateringhall from "./cateringhall";
import availabiledate from "./availabiledate";
import availabiletime from "./availabiletime";
import cateringprodcutlist from "./cateringprodcutlist";
import cateringproductdetail from "./cateringproductdetail";
import cateringcartlistdetail from "./cateringcartlistdetail";
import cartdate from "./cartdate";
import carttime from "./carttime";
import uploadDataImage from "./cateringuploadlogo";
import deleteDataImage from "./cateringdeletelogo";
import deleteCateringCart from "./cateringdeletecart";
import orderrequestlist from "./orderrequestlist";
import cateringpaymentdata from "./cateringpaymentdata";
import reservationdate from "./reservationdate";
import reservationtime from "./reservationtime";
import reservationordersubmit from "./reservationordersubmit";
import secondaryaddress from "./secondaryaddress";
import reservationlist from "./reservationlist";
import normalpopup from "./normalpopup";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  settings: settings,
  staticblack: staticblack,
  cartlistdetail: cartlistdetail,
  outlets: outlets,
  alloutlets: alloutlets,
  zonedetail: zonedetail,
  banner: banner,
  featureproduct: featureproduct,
  product: product,
  productdetail: productdetail,
  addonproduct: addonproduct,
  login: login,
  fblogin: fblogin,
  customerdetail: customerdetail,
  forgetpassword: forgetpassword,
  registration: registration,
  changepassword: changepassword,
  updatecustomerprofile: updatecustomerprofile,
  corderdetail: corderdetail,
  porderdetail: porderdetail,
  orderdetail: orderdetail,
  printorder: printorder,
  orderhistory: orderhistory,
  promotionlist: promotionlist,
  promotionreceipt: promotionreceipt,
  applypromotion: applypromotion,
  activitycount: activitycount,
  rewardearned: rewardearned,
  rewardredeem: rewardredeem,
  pagedata: pagedata,
  menudata: menudata,
  cateringorder: cateringorder,
  cateringpast: cateringpast,
  cateringhistory: cateringhistory,
  contactdata: contactdata,
  cartdate: cartdate,
  carttime: carttime,
  uploadDataImage: uploadDataImage,
  deleteDataImage: deleteDataImage,
  deleteCateringCart: deleteCateringCart,
  newsdata: newsdata,
  cateringprint: cateringprint,
  holidays: holidays,
  categorylist: categorylist,
  subcategorylist: subcategorylist,
  ownvenuecategory: ownvenuecategory,
  hallcategory: hallcategory,
  cateringhall: cateringhall,
  availabiledate: availabiledate,
  availabiletime: availabiletime,
  cateringprodcutlist: cateringprodcutlist,
  cateringproductdetail: cateringproductdetail,
  cateringcartlistdetail: cateringcartlistdetail,
  orderrequestlist: orderrequestlist,
  paymentlist: cateringpaymentdata,
  reservationdate: reservationdate,
  reservationtime: reservationtime,
  reservationordersubmit: reservationordersubmit,
  secondaryaddress: secondaryaddress,
  reservationhistory: reservationlist,
  normalpopuplist: normalpopup,
});

export default rootReducer;
